.perspective {
    perspective: 1000px;
}

.transform-style {
    transform-style: preserve-3d;
}

.group:hover .front-content {
    transform: rotateY(180deg);
}

.group:hover .back-content {
    transform: rotateY(0deg);
    opacity: 1;
}

.front-content, .back-content {
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.front-content {
    transition: transform 0.5s;
}

.back-content {
    transform: rotateY(180deg);
    transition: transform 0.5s, opacity 0.5s;
}